<template>
  <div class="modal-personal">
    <div class="modal-personal__container p-4">
      <font-awesome-icon class="modal-personal__close" @click="closeModal()" icon="times"></font-awesome-icon>
      <h2> Detalle solicitud del usuario </h2>
      <hr />
      <div class="modal-personal__info-container">
        <dl>
          <div class="modal-personal__info">
            <dt>Turno:</dt> <dd class="listas">{{ detail[0]?.usuario.turno[0].nombre_turno }}</dd>
          </div>
          <div class="modal-personal__info">
            <dt>Team:</dt> <dd class="listas">{{ detail[0]?.usuario.team.nombre_team }}</dd>
          </div>

          <!-- <div class="modal-personal__info">
            <dt>Tipo de viaje:</dt> <dd class="listas">{{ detail.ida_vuelta == '1' ? 'Solo Ida' : 'Ida y Vuelta' }}</dd>
          </div> -->

          <div v-for="(trip, idx) in detail" :key="idx" class="my-2">
            <h4>{{ trip.ida_vuelta == '1' ? 'Ida' : 'Vuelta' }}</h4>
            <div class="modal-personal__info">
              <dt>Fecha:</dt> <dd class="listas">{{ trip.transporte_tramo.fecha_viaje }} {{ trip.transporte_tramo.hora_viaje }}</dd>
            </div>
            <div class="modal-personal__info">
              <dt>Tipo de transporte:</dt>
              <dd class="listas">{{ trip.transporte_tramo.transporte_lista_destino.transporte_tipo_medio.nombre_medio }}</dd>
            </div>
            <div class="modal-personal__info">
              <dt>Tramo:</dt> <dd class="listas">{{ trip.tramo }}</dd>
            </div>
            <div class="modal-personal__info">
              <dt>Asiento:</dt> <dd class="listas">{{ trip.transporte_asiento.nombre_asiento }}</dd>
            </div>
          </div>

        </dl>
      </div>

      <div class="modal-personal__button-wrapper">
        <button class="modal-personal__close-button" @click="closeModal()">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Array,
    },
  },

  methods: {
    // Cierra modal
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>