<template>

  <section id="inscripciones-list">

    <div class="row mb-3">
      <div class="col-12 input-search">
        <input 
        type="search" 
        class="form-control input-custom"
        placeholder="Ingresa nombre colaborador" 
        v-model="filter.search"
        ref="searchInput"
        @keyup.enter="searchSolicitudes()" 
        @keyup.delete="searchSolicitudes()" 
        />
        <span class="input-search-icon">
          <font-awesome-icon icon="search"/>
        </span>
      </div>
    </div>
    
    <Loading 
      v-if="loading"
      pixeles="150px"
    />

    <template v-if="!loading">  
    
      <div class="row">
        <div class="col-12">
          <div class="table-container">
            <table 
              v-if="!loading && solicitudesArr.length !== 0"
              class="table table-custom table-borderless table-custom-responsive mb-0">
              <thead>
                <tr>
                  <th scope="col">Usuario</th>
                  <th scope="col" class="text-center">Rut</th>
                  <th scope="col" class="text-center">Teléfono</th>
                  <th scope="col" class="text-center">Correo</th>
                  <th scope="col" class="text-center">Estado</th>
                  <th scope="col" class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(sol, i) in solicitudesArr" :key="i">
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0">
                        <img 
                          :src="handleUserImage(sol)"
                          class="img-obj img-obj-60 img-obj-circle border"
                        />
                      </div>
                      <div class="flex-grow-1 ms-3">
                        {{ sol.nombre }}<br>
                        <span class="text-black-50">{{ sol.usuario.cargo[0].nombre_cargo }}</span>
                      </div>
                    </div>
                  </td>
                  <td class="th th-rut text-center">
                      {{ sol.usuario.rut_usuario }} - {{ sol.usuario.dv_usuario }}
                  </td>
                  <td class="th th-telefono text-center">
                      {{ fono(sol) }}
                  </td>
                  <td class="th th-correo text-center">
                      {{ sol.usuario.correo ? sol.usuario.correo : '-' }}
                  </td>
                  <td class="th th-estado text-center">
                    <span  
                      v-if="getState(sol) == 'sin solicitud'" 
                      class="badge badge-custom badge-custom-blue-light d-block">
                      Sin solicitud
                    </span>
                    <span 
                      v-if="getState(sol) == 'activa'"
                      class="badge badge-custom badge-custom-green-light d-block">
                      Activa
                    </span>
                  </td>
                  <td class="th th-accion text-center">
                    <div class="dropdown dropdown-custom d-flex justify-content-center">
                      <button 
                        class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                        type="button"
                        data-bs-toggle="dropdown">
                        <font-awesome-icon icon="ellipsis-vertical"/>
                      </button>
                      <ul 
                        class="dropdown-menu"
                      >
                        <li v-if="getState(sol) == 'sin solicitud'">
                          <a 
                            @click="solicitar(sol)"
                            class="dropdown-item" 
                            href="javascript:">
                            <i class="fa-regular fa-circle-check pe-2 color-main"></i>
                            Solicitar
                          </a>
                        </li>
                        <li v-if="getState(sol) == 'activa'">
                          <a 
                            @click="ver(sol)"
                            class="dropdown-item" 
                            href="javascript:">
                            <i class="fa-solid fa-magnifying-glass pe-2 color-main"></i>
                            Ver detalles
                          </a>
                        </li>
                        <li v-if="getState(sol) == 'activa'">
                          <a 
                            @click="editar(sol)"
                            class="dropdown-item" 
                            href="javascript:">
                            <i class="fa-solid fa-pencil pe-2 color-main"></i>
                            Editar
                          </a>
                        </li>
                        <li v-if="getState(sol) == 'activa'">
                          <a 
                            @click="eliminar(sol)"
                            class="dropdown-item" 
                            href="javascript:">
                            <i class="fa-solid fa-trash-can pe-2 color-main"></i>
                            Eliminar
                          </a>
                        </li>
                      </ul>
                  </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <SinResultados msg="Aceptar" custom-click @click="nuevaBusqueda"  v-if="!loading && solicitudesArr.length === 0"/>

          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-12">
          <div
            class="users-list__pagination" 
            v-if="this.pagination.page_count > 1"
          >
            <paginate 
              v-model="pagination.actual_page" 
              :page-count="pagination.page_count" 
              :page-range="pagination.page_range"
              :click-handler="pagination.click_handler" 
              :prev-text="pagination.prev_text" 
              :next-text="pagination.next_text"
              :container-class="pagination.container_class" 
              :page-class="pagination.page_class"
            >
            </paginate>
          </div>
          <div v-show="modal_detail">
            <ModalDetailPersonal 
              :detail="solicitudes_list" 
              @close-modal="modal_detail = false" 
            />
          </div>
          <Question 
            v-if="open_question_modal" 
            :msg="question_modal_msg" 
            :hideCancel="false" 
            @cancel="cancelQuestion"
            @accept="acceptQuestion"
          />
          <Status 
            v-if="open_modal_status" 
            :msg="modal_status_msg" 
            :status="modal_status" 
            @close="acceptStatus" 
          />
        </div>
      </div>
    </template>
    
  </section>

</template>

<script>
  import { mapActions, mapState } from "vuex";
  import Question from "../Modales/Question.vue";
  import Status from "../Modales/Status.vue";
  import ModalDetailPersonal from "./ModalDetailPersonal.vue";

  //FF
  import Loading from "@/components/Loading.vue";
  import SinResultados from "@/components/SinResultados.vue";

  export default {
    components: {
      Question,
      Status,
      ModalDetailPersonal,

      //FF
      Loading,
      SinResultados
    },

    data() {
      return {
        delete_img: require("@/assets/img/Eliminar.svg"),
        user_default_img: require("@/assets/img/user_default_img.svg"),
        open_question_modal: false,
        question_modal_msg: "",
        open_modal_status: false,
        modal_status_msg: "",
        modal_status: true,
        id_solicitud: [],
        id_usuario: "", 
        modal_detail: false,
        pagination:{
          actual_page: 1,
          page_count: 0,
          page_range: 3,
          click_handler: this.changePage,
          prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
          next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
          container_class: "users-list__pagination-container",
          page_class:"pagination-item"
        },
        solicitudes_limit: '30',

        //FF
        loading: true,
        filter: { 
          search: ''
        },
      };
    },
    
    computed: {
      ...mapState("transporteModule", [
        "usuarios_list", 
        "solicitudes_list",
        "solicitudesPaginationArr",
        "solicitudesArr"
      ])
    },

    watch: {
      "filter.search"(value) {
        if(value === '') {
          this.searchSolicitudes();
        }
      },
    },

    async created() {
      await this.searchSolicitudes();
    },

    methods: {
      ...mapActions("transporteModule", [
        "getUsuarios", 
        "getFindSolicitudes", 
        "getSolicitudesPagination",
        "getSolicitudesPaginated",
        'deleteSolicitudAction'
      ]),
      nuevaBusqueda() {
        this.filter.search = '';
        this.searchSolicitudes();
        this.$refs.searchInput.focus();
        
      },
      async searchSolicitudes() {
        this.loading = true;
        await this.getSolicitudesPages();
        if(this.solicitudesPaginationArr.length > 0){
          this.pagination.actual_page = 1;
          await this.getSolicitudesByPage(this.pagination.actual_page);
        }else{
          this.pagination.actual_page = 0
          await this.getSolicitudesByPage(0)
        }

        this.loading = false;
      },

      async getSolicitudesPages(){
        const data_ = {
          id_empresa: this.$ls.get("user").empresa[0].id_empresa,
          nombre_usuario: this.filter.search,
          limite: this.solicitudes_limit,
        };
        await this.getSolicitudesPagination(data_);

        this.pagination.page_count = this.solicitudesPaginationArr.length;
      },

      async getSolicitudesByPage(page){
        this.loading = true;
        const data_ = {
          id_empresa: this.$ls.get("user").empresa[0].id_empresa,
          id_usuario: page === 0 ? [] : this.solicitudesPaginationArr[(page -1)].usuarios,
        };
        await this.getSolicitudesPaginated(data_);
        this.loading = false;
      },

      async changePage(page){
        this.loading = true;
        this.pagination.actual_page = page;
        await this.getSolicitudesByPage(this.pagination.actual_page);
        this.loading = false;
      },

      handleUserImage(data) {
        if (data.usuario.imagen_perfil) {
          return data.usuario.imagen_perfil;
        } else {
          return this.user_default_img;
        }
      },

      fono(user) {
        if (user.usuario.telefono_movil) {
          return user.usuario.telefono_movil;
        } else if (user.usuario.telefono_fijo) {
          return user.usuario.telefono_fijo;
        } else {
          return "000 00 000";
        }
      },

      filterText() {
        this.usuarios = [];
        if (this.filter.search !== 0) {
          this.usuarios = this.usuarios_list.filter((e) =>
            e.nombre.toLowerCase().includes(this.filter.search.toLowerCase())
          );
        } else {
          this.usuarios = this.usuarios_list;
        }
      },

      async solicitar(user) {
        this.$router.push({ 
          name: "transporte-inscripciones-add", 
          params:{ id: user.usuario.id_usuario, action:'add' }
        });
      },
      
      async editar(user) {
        this.$router.push({
          name: "transporte-inscripciones-edit",
          params: { id: user.usuario.id_usuario, action:'edit' },
        });
      },

      // Obtiene el estado de la solicitud
      getState(user){
        if (user.solicitudes.length == 0) return 'sin solicitud';
        else return 'activa'
      },

      async eliminar(user) {
        if (user.solicitudes.length == 0) {
          this.open_modal_status = true;
          this.modal_status_msg = "No hay solicitudes para eliminar";
          this.modal_status = true;
        } else {
          let deleted = [];
          let msg = "";
          user.solicitudes.forEach((s) => {
            deleted.push(s.id_solicitud);
            msg = msg + " " + s.id_solicitud;
          });
          this.id_solicitud = deleted;
          this.id_usuario = user.usuario.id_usuario;
          // abrir modal
          this.open_question_modal = true;
          this.question_modal_msg = "¿Está seguro de eliminar la solicitud?";
        }
      },
      async acceptQuestion() {
        this.open_question_modal = false;
        const data = {
          idSolicitud: this.id_solicitud,
          idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
          idUsuario: this.id_usuario,
        };
        await this.deleteSolicitudAction(data);
        this.toastSolicitudDeleteSuccess();
      },
      cancelQuestion() {
        this.open_question_modal = false;
      },
      acceptStatus() {
        this.open_modal_status = false;
        this.modal_status_msg = "";
        window.location.reload()
      },

      async ver(user){
        this.loading = true;
        let data = {
          idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
          idUsuario: user.usuario.id_usuario,
        };
        await this.getFindSolicitudes(data); // asigna solicitudes_list
        this.loading = false;  
        this.modal_detail = true;
      },

      toastSolicitudDeleteSuccess() {
        this.$toast.open({
          message: 'La solicitud ha sido eliminada correctamente.',
          type: 'success',
          duration: 5000,
          position: 'top-right'
        }); 
      }
    },
  };
</script>

<style lang="scss" scoped>
*::v-deep .question-modal-container-message {
  height: auto;
  margin-top: 10%;
}
</style>